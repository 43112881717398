import { render, staticRenderFns } from "./BlokSectionNews.vue?vue&type=template&id=56580032&"
import script from "./BlokSectionNews.vue?vue&type=script&lang=js&"
export * from "./BlokSectionNews.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SolidChevronRightIcon: require('/builds/docue/website/node_modules/@nuxt-hero-icons/solid/src/components/ChevronRightIcon.vue').default,CustomLink: require('/builds/docue/website/components/CustomLink.vue').default})
