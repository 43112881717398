//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import blok from '~/mixins/blok'
export default {
  mixins: [blok],
  data () {
    return {
      articles: []
    }
  },
  fetchKey (getCounter) {
    return `blok-section-news:${this.blok._uid}:${getCounter('blok-section-news')}`
  },
  async fetch () {
    const response = await this.$sb.list(this.$url.blogListing().substring(1), {
      sort_by: 'first_published_at:desc',
      per_page: '3',
    })
    this.articles = response.data.stories.map((story) => {
      return {
        id: story.uuid,
        title: story.content.title,
        author: story.content.author,
        intro: story.content.intro,
        slug: story.slug,
        date: new Date(story.first_published_at || story.created_at),
        author_image: this.$sb.parseImage(story.content.author_image)
      }
    })
  },
}
